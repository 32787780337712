import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    RECEIVE_V_LIST2,
    SET_MASK,
} from '../../../store/fuerzaventas/gestionprecios/types'

import {cabecera}from '../../../store/fuerzaventas/gestionprecios/actions'
import './table.css'

import makeStyles from '@material-ui/core/styles/makeStyles';
import * as XLSX from "xlsx";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { closeMessageBox, openMessageBox } from "../../../store/main/actions";
import { onUpdateFieldFilter} from "../../../store/fuerzaventas/gestionprecios/actions"
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '120%'
    },
    progress: {
        height: '2rem',
        width: '2rem',
        '@media (min-width: 701px)': {
            height: '2.5rem',
            width: '2.5rem',
        },
    },
    content: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 'calc(50% - 20px)',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const GestionPreciosImportar = () => {
    const classes = useStyles();
    const loading = useSelector(state => state.gestionprecios.loading);
    const store = useSelector(state => state.gestionprecios.stored);
    const indexPago = useSelector(state => state.gestionprecios.filters.indexPago);
    const indexCodigo = useSelector(state => state.gestionprecios.filters.indexCodigo);
    const filters = useSelector(state => state.gestionprecios.filters);
    const col =['Codigo', 'Descripcion', 'UnidadMedida', 'PrecioEstandar', 'PrecioMinimo', 'Descuento', 'Cantidad', 'TipoDePago', 'Segmento']
//console.log(store.col) Codigo	Descripcion	UnidadMedida	PrecioEstandar	PrecioMinimo	Descuento	Cantidad	TipoDePago

    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [file, setFile] = useState([]);       
    const [head, setHead] = useState([]);

    useEffect(()=> {
        tipoPago()
     
    }, [])

    useEffect(()=> {
        filters.distribuidorId !== 0 &&  ListaPrecio()
    }, [filters.distribuidorId])

    useEffect(() => {
        setData(store.data)
       
        if (store.data.length > 0 ){
        }
        return () => {
             setData( [])
        };
    }, [ store.data]);

    function tipoPago() {
        if(filters.distribuidorId === 0){
            dispatch(
                openMessageBox({
                  button: "ok",
                  icon: "warning",
                  message: "Debe seleccionar un Agente y una lista de Precios..",
                  callback: () => dispatch(closeMessageBox()),
                })
              );
             
              return;
        }    
    };

    function ListaPrecio() {
        if(filters.codigoLista === 0){
            dispatch(
                openMessageBox({
                  button: "ok",
                  icon: "warning",
                  message: "Debe seleccionar una Lista de Precios..",
                  callback: () => dispatch(closeMessageBox()),
                })
              );
              
              return;
        }    
    };

    function readExcel(par) { //=>

        dispatch({ type: SET_MASK, loading: true });
        console.log('Aqui lo puedo Limpiar')
        dispatch(onUpdateFieldFilter("indexPago", []));
        dispatch(onUpdateFieldFilter("indexCodigo", []));

        if (par === '' || par === undefined) {
            setFile('')
            return
        }

        dispatch({ type: RECEIVE_V_LIST2, columns: [], payload: [] });
      
        const allowedExtensions = /(.xls|.xlsx)$/i;
        if (!allowedExtensions.test(par.name)) {
            alert('USE EL FORMATO CORRECTO PARA LA CARGA');
            return
        } else {

            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(par);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;

                    const wb = XLSX.read(bufferArray, { type: "buffer" });
                   
                    const wsname = wb.SheetNames[0];

                    const ws = wb.Sheets[wsname];

                    const data = XLSX.utils.sheet_to_json(ws);
                   

//*********************Validación si Carga un Archivo Vacio con Formato Correcto**************** */
                // if(wb.Strings.length -1 === col.length){
                    
                //     dispatch(
                //     openMessageBox({
                //         button: "ok",
                //         icon: "warning",
                //         message: "El Archivo no puede estar vacio..",
                //         callback: () => dispatch(closeMessageBox()),
                //     })
                //     );
                //     dispatch({ type: SET_MASK, loading: false });
                
                //     return;
                // }
                   
                    
                var local = [];

            //     setHead(head.push(Object.keys(data[0])));
            //     // eslint-disable-next-line array-callback-return
            
            //     let leng = head.length -1
                        
            //         if(head.length > 1){
            //             let head2 = []
            //             head2.push(head[leng])
                        
            //             if(JSON.stringify(col) === JSON.stringify(head2[0])=== true ){
                        
            //                 head2.map((item, i) => {
            //                 for (var i = 0; i < item.length; i++) {
            //                     //Para obtener el objeto de tu lista
            //                     var hotel2 = item[i];
            //                     local.push(hotel2);
            //                 }
            //             })
            //                 setHead(local);
            //                 resolve(data);
            //                 setFile(data);
            //             }else{
                            
            //                 dispatch(
            //                     openMessageBox({
            //                     button: "ok",
            //                     icon: "warning",
            //                     message: 'Formato de Columnas no Válido o el archivo contiene espacios en blanco Alfa',
            //                     callback: () => dispatch(closeMessageBox()),
            //                     })
            //                 );
            //                 setHead([])
            //                 dispatch({ type: SET_MASK, loading: false });
            //                 return; 
            //             }

            //         }else{ 

            //             if(JSON.stringify(col) === JSON.stringify(head[0])=== true ){
                        
            //                 head.map((item, i) => {
            //                 for (var i = 0; i < item.length; i++) {
            //                     //Para obtener el objeto de tu lista
            //                     var hotel = item[i];
            //                     local.push(hotel);
            //                     }
            //                 })
            //                 setHead(local);

            //                 resolve(data);
            //                 setFile(data);
            //             }else{
            //                 dispatch(
            //                     openMessageBox({
            //                     button: "ok",
            //                     icon: "warning",
            //                     message: 'Formato de Columnas no Válido o el archivo contiene espacios en blanco Beta',
            //                     callback: () => dispatch(closeMessageBox()),
            //                     })
            //                 );
            //                 setHead([])
            //                 dispatch({ type: SET_MASK, loading: false });
            //                 return; 
            //             }
            //         }
            //     };

            //     fileReader.onerror = (error) => {
            //         reject(error);
            //     };
            // });
            // promise.then((d) => {

            //     dispatch({ type: RECEIVE_V_LIST2, columns: Object.keys(d[0]), payload: d });
            //     setTimeout(() => {
            //         dispatch({ type: SET_MASK, loading: false });
            //     }, 2000);

            setHead (head.push(Object.keys(data[0])));
                //console.log(head)

                    head.map((item, j)=> {
                        for (var i=0; i< item.length; i++)
                        {
                        
                            var hotel = item[i]
                            
                            local.push(hotel)
                            
                        }
                    })
                    setHead(local);
                    resolve(data);
                    setFile(data);
                };

                fileReader.onerror = (error) => { reject(error); };
            });


            promise.then((d) => {
                       
                        
                dispatch({ type: RECEIVE_V_LIST2, columns:Object.keys(d[0]), payload: d });
                dispatch(cabecera());
                setFile('')
                
                setTimeout(() => {
                        dispatch({ type: SET_MASK, loading: false });
                    //}, d.lenght > 250 ? d.lenght*4: d.lenght*2);
                }, 3000);




            });
        }
    };

    function HeadTable() { 
        
        if (head.length > 0) {

            return head.map((key, index) => {
                return <th key={index} style={{
                    backgroundColor: 'lightsteelblue', height: '100%', textAlign: 'center', width: '800px',
                    wordwrap: 'break-word'
                }}>{key} </th>
            })
        } else {
            return null
        }
    };

    return (
        <>
            <div style={{ backgroundColor: 'transparent', width: '98%' }} >
                <span style={{ fontSize: '102x', marginTop: '25px', marginLeft: '10px' }}> Importar Precios</span>
                < div style={{ display: loading ? 'block' : 'none' }}>
                    <div className={classes.content}>
                        {/* <CircularProgress size='' className={classes.progress} color='secondary' /> */}
                    </div>
                </div>

                <Grid item xs style={{ marginTop: '15px' }}>
                    <FormControl className={classes.filterClear}>
                    {filters.distribuidorId !== 0 && filters.codigoLista !== 0 &&  <label style={{marginLeft:'20px' ,background:'#FF1100', color: 'white', borderRadius: '5px', width:'140px', height:'30px', display:false, cursor: 'pointer' }} disable = 'true'
                      > <i className={"fa fa-upload"} style={{marginTop:'8px', width:"20px", height:"20px",marginLeft:'15px', color : 'white' , marginRight: '5px'}} />Subir Archivo
                        <input type="file"
                            style={{opacity : 0}}
                            onChange={(e) => {
                                const archivo = e.target.files[0];
                                console.log(archivo)
                                readExcel(archivo);
                            }}
                        />
                     </label>}
                    </FormControl>
                </Grid>
                <span style={{ fontSize: '10px', marginTop: '20px', marginLeft: '10px' }}> </span>
                <table >
                    <thead>
                        <tr>
                            <th>-</th>
                            {data.length > 0 && < HeadTable />}
                        </tr>
                    </thead>
                    <tbody>
                        { store.data.length > 0 && data.map((rowData, id) => (
                                <tr key={id}>
                                    <th>{id + 1}</th>
                                    {store.data.length > 0 && head.map((key, index) => {
                                  
                                           return   <th key={index} style={{/*color: indexPago?.includes(id) ? 'black': indexCodigo?.includes(id) ? 'black': 'black'*/}}>
                                                        {rowData[key]}
                                                    </th>
                                        })
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>

        </>
    )
};

export default GestionPreciosImportar;