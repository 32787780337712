import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setOrder,
    onUpdateColumn,
    setSelectedRow
} from '../../../store/movilidad/ventaperdida/actions';
import { sort_by} from '../../../helpers/commons'


import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FieldSet, Table } from '@sigeco/tools';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column'
    }, 
    tableWrap: {
        flex: '1 1 auto'
    }
}));

const VentaPerdidaList = () => {
    const classes = useStyles();
    const columns = useSelector(state => state.ventaperdida.table.columns)
                    .filter(col => col.dataKey !== "PedidoId" && col.dataKey !== "DetallePedidoId");
    const loading = useSelector(state => state.ventaperdida.store.loading);
    const order = useSelector(state => state.ventaperdida.table.order);
    const orderBy = useSelector(state => state.ventaperdida.table.orderBy);
    const store = useSelector(state => state.ventaperdida.store);
  
    const dispatch = useDispatch();

    const handleDoubleClick = (id) => {
        /*
        history.push({
            hash: location.hash,
            pathname: `/fuerzaventas/reportEfectividad/${id}`,
            search: location.search
        });
        */
    }

    const handleResizeColumn = (column) => {
        dispatch(onUpdateColumn(column))
    };    

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        dispatch(setOrder(isAsc ? 'desc' : 'asc', property));
       let homes = store.data;
       homes.sort(sort_by(property, isAsc, homes[0][property]));

    };


    return (
        <FieldSet className={classes.root} iconCls="fas fa-th-list" title="Resultados">
             <div style={{ display: 'none' }}>
                <ReactHTMLTableToExcel
                    id="table-xls-button-rpt-indicadores"
                    className=""
                    table="table-to-xls"
                    filename="Rpt_Efectividad"
                    sheet="efectividad"
                    buttonText=""
                />
            </div>   
        <div className={classes.tableWrap}>
            <Table
                columns={columns}
                //count={store.total}
                loading={loading}
                onRowClick={items => dispatch(setSelectedRow(items))}
                onRowDoubleClick={item => handleDoubleClick(item.usuarioId)}
                onSort={datakey => handleRequestSort(datakey)}
                onUpdateColumn={handleResizeColumn}
                rowCount={store.data.length}
                rowGetter={index => store.data[index]}
                selected={store.selected}
                sortBy={orderBy}
                sortDirection={order}

                
            />
        </div>
    </FieldSet>
    )
};

export default VentaPerdidaList;