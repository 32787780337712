import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    btnSearch,
    btnDelete,
    onExport
} from '../../store/movilidad/ventaperdida/actions'

import { Body, Footer, Page } from '@sigeco/tools';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

import VentaPerdidaList from '../../components/movilidad/ventaperdida/VentaPerdidaList';
import VentaPerdidaFilter from '../../components/movilidad/ventaperdida/VentaPerdidaFilter';

const VentaPerdida = ({onClose, setTitle}) => {
    const loading = useSelector(state => state.listarproductos.loading);
    const dispatch = useDispatch();
    
    useEffect(() => {
        setTitle('Búsqueda');
    // eslint-disable-next-line
    }, []);

    function onBtnClose() {
        onClose();
    }

    return (
        <Page>
            <Backdrop style={{ position: 'absolute', zIndex: 1200 }} open={loading} transitionDuration={0}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Body>
                <VentaPerdidaFilter />
                <VentaPerdidaList />
            </Body>
            <Footer>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnDelete())}
                    startIcon={<Icon className="fas fa-trash"/>}
                  >
                    Eliminar
                </Button>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(onExport())}
                    startIcon={<Icon className="fas fa-file-excel"/>}
                  >
                    Exportar
                </Button>
              
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => dispatch(btnSearch())}
                    startIcon={<Icon className="fas fa-search"/>}
                >
                    Buscar
                </Button>
                <Button 
                    color="primary" 
                    size="small" 
                    variant="outlined" 
                    onClick={() => onBtnClose()} 
                    startIcon={<Icon className="fas fa-door-open"/>}
                >
                    Salir
                </Button>
            </Footer>
        </Page>
    )
};


export default VentaPerdida;