import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FieldSet } from "@sigeco/tools";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import { Icon } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import { actualizarModal, actualizaSubModal } from "../../../store/channelmap/reporteconstruccion/actions";
import NativeSelect from "@material-ui/core/NativeSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  wrap: {
    flex: '1 1 auto'
  },
  modal: {
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: 'translate(-50%, -50%)',
    maxHeight: 500,
    maxWidth: 800,
    width: "100%",
    display: "block",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1,0, 1, 5),
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 780px)": {
      width: "80%",
      fontSize: "max(1.5vw, 5px)",
    },
    "@media (max-width: 865px)": {
      width: "80%",
      fontSize: "max(1.5vw, 5px)",
    },
    "@media (max-width: 425px)": {
      width: "85%",
      fontSize: "max(2.5vw, 5px)",
    },
    "@media (max-width: 375px)": {
      width: "85%",
      fontSize: "max(3.5vw, 5px)",
    },
    "@media (max-width: 320px)": {
      width: "85%",
      fontSize: "max(3.5vw, 5px)",
    },
    borderRadius: "15px",
  },
  summary: {
    fontWeight: 700,
    backgroundColor:  "rgba(0,102,255,.25)",
  },
  bot: {
    position: 'fixed',
    marginTop: "60px",
    marginLeft: "125px",
    left:"75%",

    "@media (max-width: 1024px)": {
      marginTop: "-5px",
      marginLeft: "120px"

    },
    "@media (max-width: 768px)": {
      marginTop: "30px !important",
      marginLeft: "87px",
      fontSize: "10px",

    },
    "@media (max-width: 425px)": {
      marginTop: "10px !important",
      marginLeft: "20px !important",
      fontSize: "9px",
    },
    "@media (max-width: 375px)": {
      marginTop: "18px !important",
      marginLeft: "10px !important",
      fontSize: "9px",

    },
    "@media (max-width: 320px)": {
      marginTop: "30px !important",
      marginLeft: "-1px !important",
      fontSize: "10px !important",
    },


  },
  btnPluss: {
    maxWidth: "18px",
    maxHeight: "15px",
    minWidth: "18px",
    minHeight: "14px",
    marginLeft: "20",
    paddingLeft: "10px",
    fontWeight: "10px",
    
  },
  cuadro: {
    overflow: "auto",
    height: "250px",
    width: "690px",
    maxWidth: "700px",
    marginBottom: "20px !important",
    "@media (max-width: 768px)": {
      width: "100%",
      height: "220px !important",
      fontSize: "max(1vw, 5px)",
      marginTop: "-5px !important",
      marginLeft: "-70px",
      marginBottom:"-20px"

    },
    "@media (max-width: 425px)": {
      width: "110%",
      height: "220px !important",
      fontSize: "max(1vw, 5px)",
      marginTop: "-5px !important",
      marginLeft: "-70px",
      marginBottom:"-20px" 
    },
    "@media (max-width: 375px)": {
      width: "110%",
      height: "200px !important",
      fontSize: "max(1vw, 5px)",
      marginTop: "-15px !important",
      marginLeft: "-70px", 
      marginBottom:"-30px"
    },
    "@media (max-width: 320px)": {
      width: "110%",
      height: "200px !important",
      marginTop:"-6px",
      marginLeft :"-70px"
    },
  },
    resul:{
      fontSize: "10px !important",
      background: "white ",
      textAlign: "Left !important",
    },
    resuld: {
      fontSize: "10px !important",
      textAlign: "Right !important", 
      background: "white ",
      
    },
    nav: {
      "@media (max-width: 320px)": {

        marginTop: " 28px !important",
        marginLeft: "300px !important"
      },
      "@media (max-width: 375px)": {
        marginTop: " 30px !important",
        marginLeft: "300px !important"
      },
      "@media (max-width: 425px)": {
        marginTop: " 30px !important",
        marginLeft: "-245px !important"
      }
    },
    nav1: {

      "@media (max-width: 320px)": {
        marginLeft: "250px !important"
      },
      "@media (max-width: 375px)": {
        marginLeft: "252px !important"
      },
      "@media (max-width: 425px)": {
        marginLeft: "252px !important"
      },

    },
    tblPrincipal: {

      paddingLeft: 70, paddingtop: 40,

      "@media (max-width: 1024px)": {
        paddingLeft: "20px !important",
        width: "95% !important",
        overflowY:"hidden",

      },
      "@media (max-width: 768px)": {
        width: "92% !important",
        overflowY:"hidden",
        paddingLeft: "30px !important"
      },
      
      "@media (max-width: 425px)": {
        width: "92% !important",
        overflowY:"hidden",
        paddingLeft: "30px !important"
      },
      "@media (max-width: 375px)": {
        width: "92% !important",
        overflowY:"hidden",
        paddingLeft: "30px !important"
      },
      "@media (max-width: 320px)": {
        width: "89% !important",
        overflowY:"hidden",
        paddingLeft: "30px !important"
      },

    }

  
}));

const ReporteConstruccionList = () => {
  const classes = useStyles();
  const data = useSelector((state) => state.reporteconstruccion.data);
  const modal = useSelector((state) => state.reporteconstruccion.detalleModal);
  const modal1 = useSelector((state) => state.reporteconstruccion.detalleModal1);
  
  const [group, setGroup] = useState([]);
  const [summary, setSummary] = useState({});
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState({});
  const loading = useSelector((state) => state.reportepedidos.loading);
  const HandleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const [estado, setEstado] = useState("");

  const handleGeneral = (props) => {
    setEstado('TODOS')
    dispatch(actualizarModal(props));
    setOpen(true);
    setTitle(props);
  };

  useEffect(() => {
    if (data.length >= 1) {
      let result = _(data)
        .orderBy("dLob")
        .groupBy("dLob")
        .map((item, key) => ({
          dLob: key,
          cuentaPDV: _.sumBy(item, "cuentaPDV"),
          mercadoPDV: _.sumBy(item, "mercadoPDV"),
          galones: _.sumBy(item, "galones"),
          galonesMercado: _.sumBy(item, "galonesMercado"),
          registros: _.countBy(item, "dLob")[key],
        }))
        .value();

      setSummary({
        cuentaPDV: _.sumBy(result, "cuentaPDV"),
        mercadoPDV: _.sumBy(result, "mercadoPDV"),
        galones: _.sumBy(result, "galones"),
        galonesMercado: _.sumBy(result, "galonesMercado"),
      });

      setGroup(result);
    } else {
      return;
    }
  }, [data]);


  useEffect(() => {
  }, [modal])
  
  function onChange(event) {
    setEstado(event.target.value);
    //console.log(event.target.value);
    
   if(event.target.value === 'CLIENTE' || event.target.value === 'PROSPECTO' || event.target.value === 'CLIENTE S/C'){

     var mostrar = modal1.filter(f => f.tipo === event.target.value )
     dispatch(actualizaSubModal(modal1))
     dispatch(actualizaSubModal(mostrar))
    } else{
      dispatch(actualizaSubModal(modal1))
    }
  }
  
  const ModalContent = () => {
    return (
      <div>
        <Modal
          fullscreen={"xxl"}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={open}
          onClose={HandleClose}
        >
          <div className={classes.outer}>
          {/* <div className={classes.modal}  style={{height: modal.length === 1 ||  modal.length === 2 ? '30%' : modal.length === 3 || modal.length === 4 ?   '35%' : modal.length > 4  && modal.length < 7  ? '40%' : '60%'}} > */}
            <div className={classes.modal}  style={{height: modal.length === 1 ||  modal.length === 2 ? '40%' : modal.length === 3 || modal.length === 4 ?   '45%' : modal.length > 4  && modal.length < 7  ? '50%' : '60%'}} >
              <h2 style={{ textAlign: "center" }}>Detalle de Clientes</h2>
              &nbsp;&nbsp;&nbsp;SEGMENTO:
              <b>
                &nbsp;
                {title.dLob}
              </b>
              <br></br>
              &nbsp;&nbsp;&nbsp;CANAL:<b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{title.dsegmento}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
              <div className = {classes.nav}>
              <h3
              className = {classes.nav1}
                style={{
                  marginLeft: "310px ",
                  marginTop: "-18px",
                  marginBottom: "-15px",
                  width: "18%",
                  border: " 1px solid #C4C4C4 ",
                  borderRadius: "5px",
                }}
              >
                <NativeSelect
                  id="lang"
                  onChange={onChange}
                  style={{ width: "100%", borderStyle: "none" }}
                  value={estado}
                >
                  <option value="TODOS">Todos</option>
                  <option value="CLIENTE">Cliente</option>
                  <option value="PROSPECTO">Prospecto</option>
                  <option value="CLIENTE S/C">Cliente S/C</option>
                </NativeSelect>
              </h3>
              </div>
              <br></br>
              <br></br>
              <br></br>
              
             
              <div className={classes.cuadro}  style={{height: modal.length === 1 ? '210px' : modal.length === 2 ? '210px' : modal.length === 3 || modal.length === 4 ?  '210px' : modal.length > 4 && modal.length < 7 ? '210px' : '210px'}} >
                <table
                  className="aprobacion"
                  id="tableMod"
                  style={{
                    //Size: "max(3vw, 5px)",
                    maxWidth: "100%",
                    width: "650px",
                    marginLeft: "15px",
                    //borderColor: "white",
                    //paddingLeft: "40px",
                    borderCollapse:"collapse",
                  }}
                >
                
                 
                    <thead
                      style={{
                        //fontSize: "max(3vw, 5px)",
                        maxwidth: "700px",
                        width: "700px",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            maxwidth: "900px",
                            width: "900px",
                            //fontSize: "15px",
                          }}
                        >
                          Nombre de Cliente
                        </th>
                        <th
                          style={{
                            maxwidth: "900px",
                            width: "170px",
                            //fontSize: "15px",
                          }}
                        >
                          Tipos
                        </th>
                        <th
                          style={{
                            maxwidth: "900px",
                            width: "170px",
                            //fontSize: "15px",
                          }}
                        >
                          Galones
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {modal.length === 0 ? <tr><td style={{border:'none'}}>"No existe registros para esta consulta"</td></tr> : modal.map((item, index) => {
                        return (
                          <tr
                            style={{ backgroundColor: "Gainsboro" }}
                            key={index}
                          >
                            <td
                              className={classes.resul}
                              style = {{ background: "white "}}
                            >
                              {item.cliente}
                            </td>
                            <td
                              className={classes.resuld}
                              style = {{ background: "white "}}
                            >
                              {item.tipo}
                            </td>
                            <td
                              className={classes.resuld}
                              style = {{ background: "white "}}
                            >
                              {item.tGalones}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {loading && (
                      <tbody>
                        <tr style={{ backgroundColor: "grey" }}>
                          <td colSpan="4">
                            <img
                              alt="log"
                              src="https://app.iess.gob.ec/iess-fondo-reserva-afiliado-web/javax.faces.resource/images/load.gif.jsf;jsessionid=8949b76cfea43d283add3bd3dd2d?ln=sentinel-layout"
                              width="40"
                              height="40"
                            />
                          </td>
                        </tr>
                      </tbody>
                    )}
                    </table>
                </div>
                

              {!loading && <></>}
              <Button
                  variant="contained"
                  color="primary"
                  onClick={HandleClose}
                  className={classes.bot}
                  style={{marginTop:modal.length === 1 ||  modal.length === 2 ? -140 : modal.length === 3 || modal.length === 4 ?   -110 : modal.length > 4  && modal.length < 7  ? -80 : -10}}
                >
                  Cerrar
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }; /* modal content */

  return (
    <FieldSet
      className={classes.root}
      iconCls="fas fa-th-list"
      title="Resultados"
    >
      <div className={classes.tblPrincipal} >
        <div style={{ display: "none" }}>
          <ReactHTMLTableToExcel
            id="table-xls-button-rpt-indicadores"
            className=""
            table="table-to-xls"
            filename="Rpt_Indicadores"
            sheet="Indicadores"
            buttonText=""
          />
        </div>
        <table  id="table-to-xls" style={{ width: 940}} >
          <thead>
            <tr>
              <th rowSpan="3" style={{ backgroundColor: "#BFD9FF" }}>SEGMENTO</th>
              <th colSpan="5"style={{ backgroundColor: "#BFD9FF" }}># DE PUNTOS DE VENTA</th>
              <th colSpan="5" style={{ backgroundColor: "#F3F781" }}>
                GALONES / MES
              </th>
            </tr>
            <tr>
              <th colSpan="4" style={{ backgroundColor: "#BFD9FF" }}>COBERTURA</th>
              <th style={{ backgroundColor: "#BFD9FF" }}>%</th>
              <th colSpan="4" style={{ backgroundColor: "#F3F781" }}>
                PARTICIPACIÓN
              </th>
              <th style={{ backgroundColor: "#F3F781" }}>%</th>
            </tr>
            <tr>
              <th style={{ backgroundColor: "#BFD9FF" }} colSpan="1">CANAL</th>
              <th style={{ backgroundColor: "#BFD9FF" }}>Puntos Actuales</th>
              <th style={{ backgroundColor: "#BFD9FF" }}>Puntos Mercado</th>
              <th style={{ backgroundColor: "#BFD9FF" }}>Cobertura</th>
              <th style={{ backgroundColor: "#BFD9FF" }}>Puntos Potenciales</th>
              <th style={{ backgroundColor: "#F3F781" }}>CANAL</th>
              <th style={{ backgroundColor: "#F3F781" }}>Actual</th>
              <th style={{ backgroundColor: "#F3F781" }}>Mercado</th>
              <th style={{ backgroundColor: "#F3F781" }}>Participación</th>
              <th style={{ backgroundColor: "#F3F781" }}>Potencial</th>
            </tr>
          </thead>
          {group.map((item, key) => {
            return (
              <tbody key={`rpt-${key}`}>
                {data
                  ?.filter((f) => f.dLob === item.dLob)
                  .map((d, k) => (
                    <tr key={`rpt-${key}-${k}`}>
                      {k === 0 && <td rowSpan={item.registros}>{d.dLob}</td>}
                      <td style={{ textAlign: "left" }}>
                        <Button
                          className={classes.btnPluss}
                          size="small"
                          startIcon={<Icon className="fa fa-plus-circle" />}
                          onClick={() =>
                            handleGeneral({
                              dLob: d.dLob,
                              dsegmento: d.dSegmento,
                            })
                          }
                        />
                        {d.dSegmento}
                      </td>
                      <td>{d.cuentaPDV ? d.cuentaPDV : 0}</td>
                      <td>{d.mercadoPDV ? d.mercadoPDV : 0}</td>
                      <td>
                        {(
                          (parseFloat(d.mercadoPDV) === 0
                            ? 0.0
                            : d.cuentaPDV / d.mercadoPDV) * 100
                        ).toFixed(2)}
                        %
                      </td>
                      <td>{d.mercadoPDV - d.cuentaPDV}</td>
                      <td style={{ textAlign: "left" }}>
                        {d.dSegmento ? d.dSegmento : 0}
                      </td>
                      <td>
                        {d.galones
                          ? Intl.NumberFormat("es-MX").format(d.galones)
                          : 0}
                      </td>
                      <td>
                        {d.galonesMercado
                          ? Intl.NumberFormat("es-MX").format(d.galonesMercado)
                          : 0}
                      </td>
                      <td>
                        {(
                          (parseFloat(d.galonesMercado) === 0
                            ? 0.0
                            : d.galones / d.galonesMercado) * 100
                        ).toFixed(2)}
                        %
                      </td>
                      <td>{d.galonesMercado - d.galones}</td>
                    </tr>
                  ))}
                <tr>
                  <th style={{ backgroundColor: "#BFD9FF" }}></th>
                  <th style={{ backgroundColor: "#BFD9FF" }}>TOTAL</th>
                  <th style={{ backgroundColor: "#BFD9FF" }}>
                    {item.cuentaPDV
                      ? Intl.NumberFormat("es-MX").format(item.cuentaPDV)
                      : 0}
                  </th>
                  <th style={{ backgroundColor: "#BFD9FF" }}>
                    {item.mercadoPDV
                      ? Intl.NumberFormat("es-MX").format(item.mercadoPDV)
                      : 0}
                  </th>
                  <th style={{ backgroundColor: "#BFD9FF" }}>
                    {(
                      (item.mercadoPDV === 0
                        ? 0.0
                        : item.cuentaPDV / item.mercadoPDV) * 100
                    ).toFixed(2)}
                    %
                  </th>
                  <th style={{ backgroundColor: "#BFD9FF" }}>{item.mercadoPDV - item.cuentaPDV}</th>
                  <th style={{ backgroundColor: "#F3F781" }}>TOTAL</th>
                  <th style={{ backgroundColor: "#F3F781" }}>
                    {Intl.NumberFormat("es-MX").format(item.galones)}
                  </th>
                  <th style={{ backgroundColor: "#F3F781" }}>
                    {Intl.NumberFormat("es-MX").format(item.galonesMercado)}
                  </th>
                  <th style={{ backgroundColor: "#F3F781" }}>
                    {(
                      (item.galonesMercado === 0
                        ? 0.0
                        : item.galones / item.galonesMercado) * 100
                    ).toFixed(2)}
                    %
                  </th>
                  <th style={{ backgroundColor: "#F3F781" }}>
                    {item.galonesMercado - item.galones}
                  </th>
                </tr>
              </tbody>
            );
          })}
          {summary.mercadoPDV > 0 && (
            <tfoot>
              <tr>
                <th style={{ backgroundColor: "#5f9efc"}}></th>
                <th style={{ backgroundColor: "#5f9efc", color : "Black"  }}>TOTAL</th>
                <th style={{ backgroundColor: "#5f9efc", color : "Black"}}>{Intl.NumberFormat("es-MX").format(summary.cuentaPDV)}</th>
                <th style={{ backgroundColor: "#5f9efc", color : "Black"}}>{Intl.NumberFormat("es-MX").format(summary.mercadoPDV)}</th>
                <th style={{ backgroundColor: "#5f9efc", color : "Black"}}>
                  {((summary.cuentaPDV / summary.mercadoPDV) * 100).toFixed(2)}%
                </th>
                <th style={{ backgroundColor: "#5f9efc", color : "Black"}}>{summary.mercadoPDV - summary.cuentaPDV}</th>
                <th style={{ backgroundColor: "#ead21e", color : "Black" }}>TOTAL</th>
                <th style={{ backgroundColor: "#ead21e", color : "Black" }}>
                  {Intl.NumberFormat("es-MX").format(summary.galones)}
                </th>
                <th style={{ backgroundColor: "#ead21e", color : "Black" }}>
                  {Intl.NumberFormat("es-MX").format(summary.galonesMercado)}
                </th>
                <th style={{ backgroundColor: "#ead21e", color : "Black" }}>
                  {(
                    (summary.galones === 0
                      ? 0.0
                      : summary.galones / summary.galonesMercado) * 100
                  ).toFixed(2)}
                  %
                </th>
                <th style={{ backgroundColor: "#ead21e", color : "Black" }}>
                  {summary.galonesMercado - summary.galones}
                </th>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      <div className="App">{open ? <ModalContent /> : null}</div>
    </FieldSet>
  );
};

export default ReporteConstruccionList;
