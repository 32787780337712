import axios from "axios"; 
import { IdentityUrl } from "../../../config";
import { push } from 'connected-react-router';
import { closeMessageBox, openMessageBox } from "../../main/actions";

import {
  CLEAN_FORM, 
  UPDATE_FIELD_FILTER,
  ERROR_V_LIST,
  RECEIVE_V_LIST,
  REQUEST_V_LIST,
  SET_PAGE,
  SET_SELECTED_ROW,
  SET_MASK,
  SET_ORDER,
  ADVANCED_SEARCH_CLOSE,
  ADVANCED_SEARCH_OPEN,
  CLEAN_DATA2,
  RECEIVE_V_LIST2
} from "./types";

import { exportToSpreadsheet, cortarArreglo } from "../../../helpers/commons";

export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});


//======================
export const onExport = () => async (dispatch, getState) => {
  // no se usa otra forma de exportar

  if (getState().gestionprecios.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente.",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }

  if (getState().gestionprecios.filters.codigoLista   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar una Lista de Precios.",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }

  if (getState().gestionprecios.store.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para exportar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }


  try {
    dispatch({ type: SET_MASK, loading: true });
    let head = getState().gestionprecios.tablexport.columns;
    let json = getState().gestionprecios.store.data;


    exportToSpreadsheet(cortarArreglo(head,json), "Gestion de Precios");
  } catch (error) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    setTimeout(()=>{dispatch({ type: SET_MASK, loading: false })}, 1000)
  }
};
//=====================


export const onSearchListarPreciosClick = () => async (dispatch, getState) => {
  let fields = [
    { dataKey: "nombre", label: "Lista Precios", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de Lista de Precios",
      getData: async (params) => {
        let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/listarPrecios?`
        + `distribuidorId=${getState().gestionprecios.filters.distribuidorId}`;
        let response = await axios.get(encodeURI(url), { params: params });

        const aux = {
          data: response.data,
          pageSize: 50,
          start: 0,
          total: response.data.length,
        };
        return aux;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("dLista", result[0].nombre)
          );
          dispatch(
            onUpdateFieldFilter("codigoLista", `${result[0].listaPrecioId}`)
          );
         // dispatch(onUpdateFieldFilter("nombre", ""));
        }
      },
    })
  );
}

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
          dispatch(onUpdateFieldFilter("codigoLista", 0));
          dispatch(onUpdateFieldFilter("dLista", ""));
          dispatch(onUpdateFieldFilter("nombre", ""));

        }
      },
    })
  );
};

export const btnSearch = () => async (dispatch, getState) => {
  if (getState().gestionprecios.store.loading) {
    return;
  }

  if (getState().gestionprecios.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }

  if (getState().gestionprecios.filters.codigoLista === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar una Lista de Precios.",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }

  dispatch({ type: SET_MASK, loading: true });
  
  let source = axios.CancelToken.source();
  dispatch({ type: REQUEST_V_LIST, source });

  try {
    let url =  `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Tomas/listarprecioproducto?` +
      `distribuidorId=${getState().gestionprecios.filters.distribuidorId}` +
      `&listaPrecioId=${getState().gestionprecios.filters.codigoLista}`+
      `&nombre=${getState().gestionprecios.filters.nombre}`;


    let response = await axios.get(encodeURI(url));
    let data = response.data;
   
    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let columnas = getState().gestionprecios.table.columns;
    
    dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_V_LIST });
    dispatch({ type: SET_MASK, loading: false });

    if (error.response) {
      msgError =
        error.response.data.eventLogId === 0
          ? "No existe Registro  para esta consulta "
          : `EventoId: ${error.response.data.eventLogId}. ` +
            error.response.data.message;
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};

export const onImport = () => async (dispatch, getState) => {

  dispatch({ type: SET_MASK, loading: true });

    
    let url2 =  `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/listartipopago?` +
    `distribuidorId=${getState().gestionprecios.filters.distribuidorId}` +
    `&listaPrecioId=${getState().gestionprecios.filters.codigoLista}`
  
    let response2 = await axios.get(encodeURI(url2));
    //console.log(response2.data.producto)
    //console.log(response2.data.tipoPago)

  
  if (getState().gestionprecios.filters.distribuidorId === 0) {
    dispatch(openMessageBox({
      button: 'ok',
      icon: 'warning',
      message: "Debe seleccionar un Distribuidor.",
      callback: () => dispatch(closeMessageBox())
    }));
    dispatch({ type: SET_MASK, loading: false });
    return;
  }

  if (getState().gestionprecios.filters.codigoLista === 0) {
    dispatch(openMessageBox({
      button: 'ok',
      icon: 'warning',
      message: "Debe seleccionar una Lista de Precios.",
      callback: () => dispatch(closeMessageBox())
    }));
    dispatch({ type: SET_MASK, loading: false });
    return;
  }

  if (getState().gestionprecios.stored.data.length === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "No hay información para importar.",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    dispatch({ type: SET_MASK, loading: false });
    return;
  }

//////////////////////////TIPO DE PAGO NO EXISTE//Necesito saber que columnas tienen la falla/////////////////////////////
//const codigoprod  = response2.data.producto //este debe ser el resultado del api esta validacion no va porque si se pueden crear codigos nuevos
const  tipopago = response2.data.tipoPago


  let data = getState().gestionprecios.stored.data;
  let indexPago= [];
  //obtiene los indices  true y false ??false los malos??
  let result = data?.map((el,i)=>{
    return tipopago.includes(el.TipoDePago) 
   })

  //aqui solo obtengo los numeros de fila para pintar los que coincidan
    result.filter((el,i) => el === false && indexPago.push(i))
   // console.log (result, indexPago ,  'Mira el resultado id de filas')

  dispatch(onUpdateFieldFilter("indexPago", indexPago))

  if (getState().gestionprecios.filters.distribuidorId === 31){ //VALIDA DISOIL
    if( indexPago.length !== 0   ){
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: `"El tipo de Pago  "  ${data[indexPago[0]].TipoDePago}'   no se es valido'` ,
          callback: () => dispatch(closeMessageBox()),
        })
      );
      dispatch({ type: SET_MASK, loading: false });
      return;
    }
}

  let aux = getState().gestionprecios.stored.data;

  // let col = getState().gestionprecios.store.col
  // for(var w in aux){
  //   let aux2 = Object.keys(aux[w]).length
  //   console.log(aux2, 'length de columnas')
  //   if(aux2 < col.length){ //aqui debe estar en lo lque esta guardado en columnas
  //     dispatch(
  //       openMessageBox({
  //         button: "ok",
  //         icon: "warning",
  //         message: "EL Archivo seleccionado no debe tener espacio en blanco .",
  //         callback: () => dispatch(closeMessageBox()),
  //       })
  //     );

  //     dispatch({ type: SET_MASK, loading: false });
  //     dispatch({ type: RECEIVE_V_LIST2, columns: [], payload: [] });
  //     dispatch({ type: CLEAN_DATA2, });
  //     return;
  //   }
  // }

      
  var recordset = {
    'listaPrecioId': getState().gestionprecios.filters.codigoLista,
    'data': []
  };

  for (var c in aux) {
    let json = {

      "codigo": aux[c].Codigo.toString(),
      "precioEstandar": aux[c].PrecioEstandar.toString(),
      "precioVariable": aux[c].PrecioMinimo.toString(),
      "descuento": aux[c].Descuento.toString(),
      "cantidad" : aux[c].Cantidad.toString(), 
      "tipoDePago": aux[c].TipoDePago !== null ? aux[c].TipoDePago.toString() : null,
      "segmento": aux[c].Segmento !== null ? aux[c].Segmento.toString() : null,
  };
  
    recordset.data.push(json);

    
  }

  //console.log("ANTES DE ENVIAR: ",recordset)

  //return 0;

  try {
     
  
    let url = `${getState().main.resources.uriSvcSalesMobilityAPI}/api/v1/Tomas/cargapreciosproducto`;
    let response = await axios.put(url, recordset, { headers: { 'Content-Type': 'application/json' } });
    let data = response.data;
    
    dispatch(openMessageBox({ 
      button: 'ok', 
      icon: 'info', 
      message: 'Registro realizado con exito.', 
      callback: () => { 
          dispatch(closeMessageBox());
          dispatch(btnSearch())
          dispatch(push({
              pathname: `/fuerzaventas/gestionprecios`,
              search: getState().router.location.search,
              hash: getState().router.location.hash
          }));
          dispatch({ type: RECEIVE_V_LIST2, columns: [], payload: [] });
        }
  }));

  } catch (error) {
    dispatch({ type: SET_MASK, loading: false });
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    //
        dispatch({ type: SET_MASK, loading: false });
        dispatch(push({
          pathname: `/fuerzaventas/gestionprecios`,
          search: getState().router.location.search,
          hash: getState().router.location.hash
      }));

    return true
  }
};

export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().gestionprecios.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return { };
  });
};

export const cabecera = () =>(dispatch,getState)=>{

  var head = [
 
   "Codigo","Descripcion","UnidadMedida","PrecioEstandar",
   "PrecioMinimo","Descuento","Cantidad","TipoDePago","Segmento"
   ]
   
  var arreglo = getState().gestionprecios.stored.data
 
     arreglo.forEach((obj) => {
       head.forEach((key) => {
         if (!obj.hasOwnProperty(key)) {
           obj[key] = null;
         }
       });
     });
 
 }
 

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
  
  if (key !== 'nombre') {
        dispatch({ type: RECEIVE_V_LIST, column: [], payload: [] });
  }
};

export const openAdvancedSearch = (opts) => ({
  type: ADVANCED_SEARCH_OPEN,
  payload: opts,
});

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });
