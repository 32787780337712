import axios from "axios"; //;
import { IdentityUrl } from "../../../config";
//import { push } from 'connected-react-router';
//import format from "date-fns/format";
//import { IdentityUrl } from '../../../config';
import { closeMessageBox, openMessageBox } from "../../main/actions";
import {exportToSpreadsheet,cortarArreglo} from '../../../helpers/commons'
import {
  CLEAN_FORM,
  SET_STATE_FORM,
  UPDATE_FIELD_FILTER,
  ERROR_V_LIST,
  RECEIVE_V_LIST,
  RECEIVE_W_LIST,
  RECEIVE_X_LIST,
  REQUEST_V_LIST,
  REQUEST_X_LIST,
  SET_PAGE,
  SET_SELECTED_ROW,
  SET_MASK,
  SET_ORDER,
  ADVANCED_SEARCH_CLOSE,
  ADVANCED_SEARCH_OPEN,
  ON_SUCURSAL,
  SET_PAGE_SIZE
} from "./types";


export const setPage = (page) => ({ type: SET_PAGE, page });

export const cleanForm = () => ({ type: CLEAN_FORM });

//export const onUpdateFieldFilter = (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value });

export const setSelectedRow = (selected) => ({
  type: SET_SELECTED_ROW,
  selected,
});

export const setFormState = (formState, id) => (dispatch, getState) => {
  switch (formState) {
    case "edit":

      break;
    case "new":
      dispatch(cleanForm());
      break;
    case "search":
      dispatch(cleanForm());
      break;
    case "view":

      break;
    default:
      break;
  }
  dispatch({ type: SET_STATE_FORM, formState });
};

//======================
export const onImport = () => async (dispatch, getState) => {
 
  if (getState().cargamasivarutas.filters.distribuidorId   === 0) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }
  if (getState().cargamasivarutas.filters.valor === 3 && getState().cargamasivarutas.store.data4.length === 0 ) {
    dispatch(openMessageBox({
        button: 'ok',
        icon: 'warning',
        message: "Debe seleccionar un Archivo..",
        callback: () => dispatch(closeMessageBox())
    }));
    return;
  }

  // if (getState().cargamasivarutas.store.data.length === 0  ||  getState().cargamasivarutas.store.data2.length)  {
  //   dispatch(
  //     openMessageBox({
  //       button: "ok",
  //       icon: "warning",
  //       message: "No hay información para exportar.",
  //       callback: () => dispatch(closeMessageBox()),
  //     })
  //   );
  //   return;
  // }
  dispatch({ type: SET_MASK, loading: true });
      
      //var recordset = [];
      var recordset = [];
      if (getState().cargamasivarutas.filters.valor === 0){
            let aux = getState().cargamasivarutas.store.data;
           // console.log(aux);
            recordset = {
              'rutasCargaMasiva': []
            }

          for (var c in aux) {
            //console.log(aux[c].Lunes)
            let json =
              {
                  "distribuidorId":  getState().cargamasivarutas.filters.distribuidorId,
                  "codRuta": aux[c].CodRuta           ,
                  "NombreRuta": aux[c].NombreRuta,
                  "lunes": aux[c].Lunes === undefined  || aux[c].Lunes === 0 ? false : true          ,
                  "martes": aux[c].Martes === undefined ||  aux[c].Martes === 0 ? false : true          ,
                  "miercoles": aux[c].Miercoles === undefined || aux[c].Miercoles === 0 ? false : true          ,
                  "jueves": aux[c].Jueves === undefined || aux[c].Jueves === 0 ? false : true          ,
                  "viernes": aux[c].Viernes === undefined || aux[c].Viernes === 0 ? false : true          ,
                  "sabado": aux[c].Sabado === undefined || aux[c].Sabado === 0 ? false : true          ,
                  "domingo": aux[c].Domingo === undefined || aux[c].Domingo === 0 ? false : true          ,
                  "frecuencia": aux[c].Frecuencia === undefined ? '' : aux[c].Frecuencia                ,
                  "vendedor": aux[c].Vendedor === undefined ? '' : aux[c].Vendedor  ,
                  "nitVendedor": aux[c].NITVendedor === undefined ? '' : aux[c].NITVendedor                
              }     
      
          //recordset.push(json);
          recordset.rutasCargaMasiva.push(json)
          }

      }else  if (getState().cargamasivarutas.filters.valor === 1) {
          let aux = getState().cargamasivarutas.store.data2;
          recordset = {
            'rutasClienteCargaMasiva': []
          }
          
          // eslint-disable-next-line no-redeclare
          for (var c in aux) {
            let json =
              {
                  //"distribuidorId":  getState().cargamasivarutas.filters.distribuidorId,
                 "distribuidorId":  getState().cargamasivarutas.filters.distribuidorId,    
                  "codRuta": aux[c].CodRuta.toString(),
                  "nitCliente": aux[c].NITCliente === undefined ? '' : aux[c].NITCliente.toString()   , 
                  "codSucursal": aux[c].CODSucursal === undefined ? '' : aux[c].CODSucursal.toString() ,
                  "nombreSucursal": aux[c].NombreSucursal === undefined ? '' : '-'             
              }
            recordset.rutasClienteCargaMasiva.push(json)
          }
      } else {

      }

     //dispatch({ type: SET_MASK, loading: true });
     const columns = getState().cargamasivarutas.table.columns;
     const columns2 = getState().cargamasivarutas.table2.columns;
//aqui cambiar a un switch para 0,1,3
//debe crear el api

  try {
    var url=''
    var response = ''
    if (getState().cargamasivarutas.filters.valor === 0){

      url =`${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Rutas/rutacargamasiva`;
     
        response = await axios.post(url, recordset,  {headers: { "Content-Type": "application/json" }, });

    }else if (getState().cargamasivarutas.filters.valor === 1){
       
         url =`${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/rutaclientecargamasiva` ;
         response = await axios.post(url, recordset,  {headers: { "Content-Type": "application/json" }, });

    }else {
      let json = JSON.stringify(getState().cargamasivarutas.store.data4)

      url =`${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/clientes/clientesucursalrutavendedor?`+ 
      `distribuidorId=${getState().cargamasivarutas.filters.distribuidorId}`;
      
      response = await axios.post(url, json,  {headers: { "Content-Type": "application/json" }, });
    }

     let data = response;

     if (data.status  === 200  && getState().cargamasivarutas.filters.valor === 1){

        // eslint-disable-next-line array-callback-return
        let t = []
        // eslint-disable-next-line array-callback-return
        getState().cargamasivarutas.store.data2.map((item, key) => {
              // eslint-disable-next-line array-callback-return
              data.data.map((e) => {         
                  if (item.NITCliente   === e.nitCliente    ){
                          item.cliente = e.cliente;
                          item.resultado =  e.resultado;
                  }
              }
            ) 
            t.push(item)
        })
        dispatch({ type: RECEIVE_W_LIST, columns: columns, payload: t}); 
      } 

      if (data.status  === 200  && getState().cargamasivarutas.filters.valor === 3){

        console.log(columns2)
        console.log(data.data)
       
        dispatch({ type: REQUEST_X_LIST, columns: columns2, payload: data.data}); 
        dispatch({ type: SET_MASK, loading: false });
      } 
    
    if ((data.status   === 201 || data.status   === 200) && getState().cargamasivarutas.filters.valor !== 3){
      dispatch(openMessageBox({ 
        button: 'ok', 
        icon: 'info', 
        message: 'Registro realizado con exito.', 
        callback: () => { 
             dispatch({ type: SET_MASK, loading: false });
           dispatch(closeMessageBox()); 
       
        }
    }));
 }
   

  } catch (error) {
    dispatch({ type: SET_MASK, loading: false });
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message:
          (error.response.data.eventLogId === 0
            ? ""
            : `EventoId: ${error.response.data.eventLogId}. `) +
          error.response.data.message,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {

    return true
  }
};
//=====================

export const btnSearch = () => (dispatch) => {
  dispatch(setPage(0));
  dispatch(onList());
};

export const onSearchDistribuidorClick = () => async (dispatch) => {
  let fields = [
    { dataKey: "razonSocial", label: "Agente", type: "string", width: 225 },
  ];

  dispatch(
    openAdvancedSearch({
      autoLoad: true,
      data: [],
      fields: fields,
      paginated: true,
      remote: true,
      title: "Selección de agente",
      getData: async (params) => {
        let url = `${IdentityUrl}/api/v1/distribuidores/porsesion`;
        let response = await axios.get(encodeURI(url), { params: params });

        return response.data;
      },
      callback: (btn, result) => {
        dispatch(closeAdvancedSearch());
        if (btn === "yes") {
          dispatch(
            onUpdateFieldFilter("distribuidorId", result[0].distribuidorId)
          );
          dispatch(
            onUpdateFieldFilter("dDistribuidor", `${result[0].razonSocial}`)
          );
      
        }
      },
    })
  );
};

export const onList = () => async (dispatch, getState) => {
  if (getState().listarproductos.store.loading) {
    return;
  }

  if (getState().listarproductos.filters.distribuidorId === 0) {
    dispatch(
      openMessageBox({
        button: "ok",
        icon: "warning",
        message: "Debe seleccionar un Agente..",
        callback: () => dispatch(closeMessageBox()),
      })
    );
    return;
  }
  dispatch({ type: SET_MASK, loading: true });
  //console.log(getState().vendedor);
  //dispatch({ type: RECEIVE_OLAS_LIST, columns: getState().vendedor.table.columns, payload: [] });
  let source = axios.CancelToken.source();
  dispatch({ type: REQUEST_V_LIST, source });

  try {
    let url =  `${getState().main.resources.uriSvcSalesMobilityAPI}api/v1/Productos/costosproductoslistar?` +
      `distribuidorId=${getState().listarproductos.filters.distribuidorId}` +
      `&nombre=${getState().listarproductos.filters.nombre}`;

    let response = await axios.get(encodeURI(url));
    let data = response.data;
    
    if (data.length === 0) {
      dispatch(
        openMessageBox({
          button: "ok",
          icon: "warning",
          message: "No existe Registro  para esta consulta",
          callback: () => dispatch(closeMessageBox()),
        })
      );
      return;
    }

    let columnas = getState().listarproductos.table.columns;
    
    dispatch({ type: RECEIVE_V_LIST, columns: columnas, payload: data });
  } catch (error) {
    let msgError = "";
    dispatch({ type: ERROR_V_LIST });
    dispatch({ type: SET_MASK, loading: false });

    if (error.response) {
      msgError =
        error.response.data.eventLogId === 0
          ? "No existe Registro  para esta consulta "
          : `EventoId: ${error.response.data.eventLogId}. ` +
            error.response.data.message;
    } else {
      msgError = "Error al conectar con el servidor";
    }

    dispatch(
      openMessageBox({
        button: "ok",
        icon: "error",
        message: msgError,
        callback: () => dispatch(closeMessageBox()),
      })
    );
  } finally {
    dispatch({ type: SET_MASK, loading: false });
  }
};


export const onUpdateColumn = (column) => (dispatch, getState) => {
  getState().listarproductos.table.columns.map((item, i) => {
    if (item.dataKey !== column.dataKey) {
      return item;
    } else {
      item.width = column.width;
    }
    return {
      // ...item,
      //  width: column.width
    };
  });
};

export const onUpdateFieldFilter = (key, value) => (dispatch, getState) => {
  dispatch({ type: UPDATE_FIELD_FILTER, key, value });
};

export const onUpdateFieldUpdate = (key, value) => (dispatch, getState) => {
  //console.log(key)
  dispatch({ type: RECEIVE_V_LIST, columns: key, payload: value });
};

export const openAdvancedSearch = (opts) => ({
  type: ADVANCED_SEARCH_OPEN,
  payload: opts,
});

export const setOrder = (order, orderBy) => ({
  type: SET_ORDER,
  order,
  orderBy,
});

export const onSucursal = (value) => (dispatch) => {
  dispatch({type : ON_SUCURSAL , payload: value})
}

export const closeAdvancedSearch = () => ({ type: ADVANCED_SEARCH_CLOSE });
export const setPageSize = (pageSize) => ({ type: SET_PAGE_SIZE, pageSize });

export const btnExport = () => async (dispatch, getState) => {
   


  if(getState().cargamasivarutas.store.data5.length === 0) {
      dispatch(openMessageBox({ 
          button: 'ok', 
          icon: 'warning', 
          message: 'No hay información para exportar.', 
          callback: () => dispatch(closeMessageBox())
      }));
      return;
  }

  try {
      dispatch({ type: SET_MASK, loading: true });

      let head = getState().cargamasivarutas.table2.columns;
     // console.log(head, "Mira Aly head")
     
              var carArray = [];
              carArray.push(head);

              let json = getState().cargamasivarutas.store.data5;
                  
              for(var i in json) {
                  carArray.push(i);
                }

      exportToSpreadsheet(cortarArreglo(head, json), 'Sucursales') ;

  } catch (error) {
      dispatch({ type: SET_MASK, loading: false });
      console.log(error)
      dispatch(openMessageBox({ 
          button: 'ok', 
          icon: 'error', 
          message: (error.response.data.eventLogId === 0 ? '' : `EventoId: ${error.response.data.eventLogId}. `) + error.response.data.message, 
          callback: () => dispatch(closeMessageBox())
      }));
  } finally {
      setTimeout(() => {dispatch({ type: SET_MASK, loading: false })}, 1000);
  }
};

